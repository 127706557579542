import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));

export default function CenteredGrid() {
    const classes = useStyles();

    return (
        <div className="page-component">
            <Container maxWidth="md">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper} xs={12}>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tristique lorem dictum blandit porttitor. Nullam at arcu et massa
                                ultrices vulputate at id enim. Mauris ac dui scelerisque, commodo nisl ac, faucibus tellus. Nullam at dui a ligula lobortis
                                volutpat a quis ipsum. Pellentesque consequat odio pharetra arcu hendrerit, sit amet commodo eros porttitor. Morbi quis aliquam
                                orci, nec varius tortor. Quisque in est tincidunt, condimentum nisl at, auctor ante. Curabitur rutrum arcu in dolor pharetra
                                luctus sit amet nec ipsum. Nunc eu augue ipsum. Nam feugiat nibh eget leo dapibus rutrum. Donec metus sem, volutpat quis mattis
                                eu, cursus id neque. Aenean id odio nunc. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                                Donec rutrum enim ut lacus facilisis rutrum. Cras semper tristique pulvinar. Nam tempor, quam ut congue pretium, augue leo
                                interdum purus, id fermentum risus diam sed nisl. Nam consectetur erat id risus maximus vulputate. Aliquam tincidunt imperdiet
                                enim ac consequat. Morbi fermentum lorem dignissim pharetra commodo. Mauris at erat congue erat molestie bibendum at at diam. In
                                nec pharetra libero, in euismod libero. Aliquam et aliquet sem. Duis quis aliquet justo.
                            </p>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
