import React from "react";
import { Parallax } from "react-parallax";
import background from "../assets/codebackground.jpg";

export default function Teaser() {
    return (
        <Parallax blur={{ min: -15, max: 15 }} bgImage={background} bgImageAlt="Code background" strength={-200}>
            Blur transition from min to max
            <div style={{ height: "200px" }} />
        </Parallax>
    );
}
