import React, { Component } from "react";
import "./App.scss";
import Helmet from "react-helmet";
import Intro from "./components/Intro";
import About from "./components/About";
import Teaser from "./components/Teaser";

class App extends Component {
    render() {
        const siteTitle = "Testing";
        const siteDescription = "Testing";

        return (
            <div className={`App body`}>
                <Helmet>
                    <title>{siteTitle}</title>
                    <meta name="description" content={siteDescription} />
                </Helmet>

                <Intro />

                <div className="firstBlock">
                    <About />
                </div>

                <Teaser />

                <div className="fullBlock">
                    <About />
                </div>
            </div>
        );
    }
}

export default App;
