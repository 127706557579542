import React, { Component } from "react";
import YoutubeBackground from "react-youtube-background";
import logo from "../assets/cottone-logo.png";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
    img: {
        zIndex: 1,
    },
});

class Intro extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className="video-background">
                <YoutubeBackground videoId="7H3nrRTiofI" className="video-background">
                    <div className="video-body">
                        <img className={classes.img} src={logo} alt="Logo" />
                    </div>
                </YoutubeBackground>
            </div>
        );
    }
}

export default withStyles(styles)(Intro);
